import numeral from "numeral";
import React, { useState } from "react";
import { Badge, Col, Form, Row } from "react-bootstrap";
import { useQuery } from "react-query";
import { VictoryLabel, VictoryPie, VictoryPortal } from "victory";
import { fetchPrices, getSinglePoolOutput } from "../../../lib/helpers";
import Pool from "./Pool";
import { Swap } from "./Swap";

const ExternalToRune = ({ theme }) => {
  const [input, setInput] = useState(500);
  const [totalPoolValueUSD, setTotalPoolValueUSD] = useState(5000000);
  const [showDetail, setShowDetail] = useState(false);
  const [showCalculation, setShowCalculation] = useState(false);
  const { status, data, error } = useQuery(["thorchain,binancecoin"], fetchPrices);

  const BNBPriceUSD = status === "success" && data.binancecoin.usd;
  const RUNEPriceUSD = status === "success" && data.thorchain.usd;

  const pool = {
    title: "Pool",
    setTotalPoolValueUSD: setTotalPoolValueUSD,
    totalPoolValueUSD,
    inputAsset: {
      ticker: "BNB",
      priceUSD: BNBPriceUSD,
      value: totalPoolValueUSD / 2,
      balance: totalPoolValueUSD / 2 / BNBPriceUSD,
      color: "#FFDC00"
    },
    outputAsset: {
      ticker: "RUNE",
      priceUSD: RUNEPriceUSD,
      value: totalPoolValueUSD / 2,
      balance: totalPoolValueUSD / 2 / RUNEPriceUSD,
      color: theme.colors[2]
    }
  };

  const output = getSinglePoolOutput(input, pool.inputAsset.balance, pool.outputAsset.balance);

  const inputValueSwapped = input * pool.inputAsset.priceUSD;
  const outputValueSwapped = output * pool.outputAsset.priceUSD;

  const slippageValueCalulation = outputValueSwapped - inputValueSwapped;
  const slippage = {
    value: slippageValueCalulation,
    color: "#FF4136",
    percentage: (slippageValueCalulation / inputValueSwapped) * 100 || 0
  };
  return (
    <>
      <div className="d-flex mb-2">
        <Form.Check
          label="Show detail"
          type="switch"
          id="showDetailExternalToRune"
          onChange={() => setShowDetail(!showDetail)}
          value={showDetail}
          className="mr-3 text-muted"
        />
        <Form.Check
          label="Show calculation"
          type="switch"
          id="showCalculationExternalToRune"
          onChange={() => setShowCalculation(!showCalculation)}
          value={showCalculation}
          className="text-muted"
        />
      </div>
      <Row>
        <Col sm={6} className="mb-3 mb-sm-0">
          <Swap
            setInput={setInput}
            input={input}
            inputAsset={pool.inputAsset}
            outputAsset={pool.outputAsset}
            output={output}
            inputValueSwapped={inputValueSwapped}
            outputValueSwapped={outputValueSwapped}
            slippage={slippage}
            showCalculation={showCalculation}
          />
        </Col>
        <Col sm={6}>
          <Pool
            pool={pool}
            showDetail={showDetail}
            showCalculation={showCalculation}
            theme={theme}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <Row className="d-flex align-items-center pt-3">
            <Col xs={{ order: 2 }} sm={{ span: 4, order: 1 }} className="d-flex flex-column">
              <Badge
                className="overline text-truncate mb-1"
                style={{ backgroundColor: pool.inputAsset.color, color: "rgba(0,0,0,.4)" }}
              >
                <small>BNB</small>
              </Badge>
              <Badge
                className="overline text-truncate mb-1"
                style={{ backgroundColor: pool.outputAsset.color, color: "rgba(0,0,0,.4)" }}
              >
                <small>RUNE</small>
              </Badge>
              <Badge
                className="overline text-truncate"
                style={{ backgroundColor: slippage.color, color: "rgba(0,0,0,.4)" }}
              >
                <small>Slippage</small>
              </Badge>
            </Col>
            <Col xs={{ order: 1 }} sm={{ span: 8, order: 2 }}>
              <VictoryPie
                style={{ labels: { fontSize: 50, fill: "rgba(0,0,0,.5)" } }}
                labelComponent={
                  <VictoryPortal>
                    <VictoryLabel />
                  </VictoryPortal>
                }
                width={250}
                height={250}
                origin={{ x: 125, y: 125 }}
                labelRadius={({ radius }) => radius + 5}
                labels={({ datum }) => `$ ${numeral(datum.y).format("0.0a")}`}
                radius={(inputValueSwapped + outputValueSwapped + Math.abs(slippage.value)) / 300}
                theme={theme}
                style={{ labels: { fontSize: 15, fill: "rgba(0,0,0,.5)" } }}
                colorScale={[pool.outputAsset.color, pool.inputAsset.color, slippage.color]}
                data={[
                  { x: "Output Value", y: outputValueSwapped },
                  { x: "Input Value", y: inputValueSwapped },
                  { x: "Slippage", y: Math.abs(slippage.value) }
                ]}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={6}>
          <Row className="d-flex align-items-center pt-3">
            <Col xs={{ order: 2 }} sm={{ span: 7, order: 1 }} className="mb-4 mb-sm-0">
              <VictoryPie
                style={{ labels: { fontSize: 30, fill: "rgba(0,0,0,.5)" } }}
                labelComponent={
                  <VictoryPortal>
                    <VictoryLabel />
                  </VictoryPortal>
                }
                width={200}
                height={200}
                labelRadius={({ radius }) => radius + 5}
                origin={{ x: 100, y: 100 }}
                labels={({ datum }) => `$ ${numeral(datum.y).format("0.0a")}`}
                radius={(pool.totalPoolValueUSD + Math.abs(slippage.value)) / 100000}
                theme={theme}
                colorScale={[pool.inputAsset.color, theme.colors[0]]}
                style={{ labels: { fontSize: 15, fill: "rgba(0,0,0,.5)" } }}
                data={[
                  { x: "Input Value", y: inputValueSwapped },
                  { x: "Total Pool Value", y: pool.totalPoolValueUSD }
                ]}
              />
            </Col>
            <Col xs={{ order: 1 }} sm={{ span: 5, order: 2 }} className="d-flex flex-column">
              <Badge
                className="overline text-truncate mb-1"
                style={{ backgroundColor: pool.inputAsset.color, color: "rgba(0,0,0,.4)" }}
              >
                <small>Swap BNB Value</small>
              </Badge>
              <Badge
                className="overline text-truncate"
                style={{ backgroundColor: theme.colors[0], color: "rgba(0,0,0,.4)" }}
              >
                <small>Total Pool Value</small>
              </Badge>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default ExternalToRune;
