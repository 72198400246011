import numeral from "numeral";
import React from "react";

const DebtRiskForMinters = () => {
  const assets = [
    {
      ticker: "SNX",
      name: "SNX",
      usd: 0.7
    },
    {
      ticker: "SUSD",
      name: "sUSD",
      usd: 0.987
    }
  ];

  const accounts = [
    {
      id: "c06ff655-3a77-488e-9a3a-c6e54038ae4f",
      snx: {
        owned: {
          amount: 100,
          value: {
            usd: 100 * 0.7
          }
        },
        staked: {
          amount: 100,
          value: {
            usd: 100 * 0.7
          }
        },
        locked: {
          amount: 100,
          value: {
            usd: 100 * 0.7
          }
        }
      },
      cRatio: 0
    },
    {
      id: "f8f68dc6-4901-4c46-9458-73bdc494a1d2",
      snx: {
        owned: {
          amount: 100,
          value: {
            usd: 100 * 0.7
          }
        },
        staked: {
          amount: 100,
          value: {
            usd: 100 * 0.7
          }
        },
        locked: {
          amount: 100,
          value: {
            usd: 100 * 0.7
          }
        }
      },
      cRatio: 0
    }
  ];

  const system = {
    parameters: {
      targetCRatio: 8
    },
    debt: {
      tokens: [
        {
          ticker: "SUSD",
          amount: 1000,
          value: 1000 * 0.987
        }
      ],
      total: 1000 * 0.987
    }
  };

  return (
    <>
      <div className="mb-3">
        <h4 className="mb-0">Assets</h4>
        {assets.map(asset => (
          <div key={asset.ticker}>
            <h5 className="mb-0">{asset.name}</h5>
            <h6 className="mb-0">Price (USD)</h6>
            <div>{numeral(asset.usd).format("$0.000")}</div>
          </div>
        ))}
      </div>
      <div className="mb-3">
        <h4 className="mb-0">System</h4>
        <h5 className="mb-0">Parameters</h5>
        <h6 className="mb-0">Target C-Ratio</h6>
        <div className="mb-1">{numeral(system.parameters.targetCRatio).format("0%")}</div>
        <h5 className="mb-0">Debt</h5>
        <h6 className="mb-0">Total</h6>
        <div>{numeral(system.debt.total).format("$0,0.00")}</div>
      </div>
      <div>
        <h4 className="mb-0">Accounts</h4>
        {accounts.map((account, i) => (
          <div key={account.id} className="mb-3">
            <h5 className="mb-2">Account {i + 1}</h5>
            <div className="d-flex">
              <div className="pr-1">
                <h6 className="mb-1">Owned</h6>
                <div className="mb-0 overline">Amount</div>
                <div>
                  {account.snx.owned.amount} <small>SNX</small>
                </div>
                <div className="mb-0 overline">Value</div>
                <div>{numeral(account.snx.owned.value.usd).format("$0.00")}</div>
              </div>
              <div className="px-1">
                <h6 className="mb-1">Staked</h6>
                <div className="mb-0 overline">Amount</div>
                <div>
                  {account.snx.staked.amount} <small>SNX</small>
                </div>
                <div className="mb-0 overline">Value</div>
                <div>{numeral(account.snx.staked.value.usd).format("$0.00")}</div>
              </div>
              <div className="px-1">
                <h6 className="mb-1">Locked</h6>
                <div className="mb-0 overline">Amount</div>
                <div>
                  {account.snx.locked.amount} <small>SNX</small>
                </div>
                <div className="mb-0 overline">Value</div>
                <div>{numeral(account.snx.locked.value.usd).format("$0.00")}</div>
              </div>
              <div className="px-1">
                <h6 className="mb-1">C-Ratio</h6>
                <div>{account.snx.locked.value - account.debt}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default DebtRiskForMinters;
