import numeral from "numeral";
import React, { useState } from "react";
import { Badge, Form } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useQuery } from "react-query";
import { VictoryLabel, VictoryPie, VictoryPortal } from "victory";
import { fetchPrices, getSinglePoolOutput } from "../../../lib/helpers";
import Pool from "./Pool";
import { Swap } from "./Swap";

const ExternalToExternal = ({ theme }) => {
  const [input, setInput] = useState(500);
  const [totalInputPoolValueUSD, setTotalInputPoolValueUSD] = useState(7500000);
  const [totalOutputPoolValueUSD, setTotalOutputPoolValueUSD] = useState(9500000);
  const [showDetail, setShowDetail] = useState(false);
  const [showCalculation, setShowCalculation] = useState(false);
  const { status, data, error } = useQuery(["thorchain,binancecoin,bitcoin"], fetchPrices);

  const BNBPriceUSD = status === "success" && data.binancecoin.usd;
  const RUNEPriceUSD = status === "success" && data.thorchain.usd;
  const BTCPriceUSD = status === "success" && data.bitcoin.usd;

  const inputPool = {
    title: "Input Pool",
    setTotalPoolValueUSD: setTotalInputPoolValueUSD,
    totalPoolValueUSD: totalInputPoolValueUSD,
    inputAsset: {
      ticker: "BNB",
      priceUSD: BNBPriceUSD,
      value: totalInputPoolValueUSD / 2,
      balance: totalInputPoolValueUSD / 2 / BNBPriceUSD,
      color: "#FFDC00"
    },
    outputAsset: {
      ticker: "RUNE",
      priceUSD: RUNEPriceUSD,
      value: totalInputPoolValueUSD / 2,
      balance: totalInputPoolValueUSD / 2 / RUNEPriceUSD,
      color: theme.colors[2]
    }
  };

  const outputPool = {
    title: "Output Pool",
    setTotalPoolValueUSD: setTotalOutputPoolValueUSD,
    totalPoolValueUSD: totalOutputPoolValueUSD,
    inputAsset: {
      ticker: "RUNE",
      priceUSD: RUNEPriceUSD,
      value: totalOutputPoolValueUSD / 2,
      balance: totalOutputPoolValueUSD / 2 / RUNEPriceUSD,
      color: theme.colors[2]
    },
    outputAsset: {
      ticker: "BTC",
      priceUSD: BTCPriceUSD,
      value: totalOutputPoolValueUSD / 2,
      balance: totalOutputPoolValueUSD / 2 / BTCPriceUSD,
      color: "#FF851B"
    }
  };

  const firstOutput = getSinglePoolOutput(
    input,
    inputPool.inputAsset.balance,
    inputPool.outputAsset.balance
  );

  const output = getSinglePoolOutput(
    firstOutput,
    outputPool.inputAsset.balance,
    outputPool.outputAsset.balance
  );

  const inputValueSwapped = input * inputPool.inputAsset.priceUSD;
  const outputValueSwapped = output * outputPool.outputAsset.priceUSD;

  const slippageValueCalulation = outputValueSwapped - inputValueSwapped;
  const slippage = {
    value: slippageValueCalulation,
    color: "#FF4136",
    percentage: (slippageValueCalulation / inputValueSwapped) * 100 || 0
  };
  const totalPoolsValue = {
    value: totalInputPoolValueUSD + totalOutputPoolValueUSD,
    color: theme.colors[0]
  };
  return (
    <div className="mb-5">
      <div className="d-flex mb-2">
        <Form.Check
          label="Show detail"
          type="switch"
          id="showDetailExternalToExternal"
          onChange={() => setShowDetail(!showDetail)}
          value={showDetail}
          className="mr-3 text-muted"
        />
        <Form.Check
          label="Show calculation"
          type="switch"
          id="showCalculationExternalToExternal"
          onChange={() => setShowCalculation(!showCalculation)}
          value={showCalculation}
          className=" text-muted"
        />
      </div>
      <Row className="mb-4">
        <Col sm={6} className="mb-3">
          <Swap
            setInput={setInput}
            input={input}
            inputAsset={inputPool.inputAsset}
            outputAsset={outputPool.outputAsset}
            output={output}
            inputValueSwapped={inputValueSwapped}
            outputValueSwapped={outputValueSwapped}
            slippage={slippage}
            showCalculation={showCalculation}
          />
        </Col>

        <Col sm={6}>
          <Row className="mb-2">
            <Col xs={6}>
              <Pool pool={inputPool} showDetail={showDetail} theme={theme} />
            </Col>
            <Col xs={6}>
              <Pool pool={outputPool} showDetail={showDetail} theme={theme} />
            </Col>
          </Row>
          <div className="text-center">
            <Badge
              className="overline"
              style={{ backgroundColor: theme.colors[0], color: "rgba(0,0,0,.4)" }}
            >
              Total Pools Value
            </Badge>
            <div className="h6 mb-0">
              <small>$</small>
              {numeral(totalPoolsValue.value).format("0.0a")}
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <Row className="d-flex align-items-center pt-3">
            <Col xs={{ order: 2 }} sm={{ span: 4, order: 1 }} className="d-flex flex-column">
              <Badge
                className="overline text-truncate mb-1"
                style={{ backgroundColor: inputPool.inputAsset.color, color: "rgba(0,0,0,.4)" }}
              >
                <small>BNB</small>
              </Badge>
              <Badge
                className="overline text-truncate mb-1"
                style={{ backgroundColor: outputPool.outputAsset.color, color: "rgba(0,0,0,.4)" }}
              >
                <small>RUNE</small>
              </Badge>
              <Badge
                className="overline text-truncate"
                style={{ backgroundColor: slippage.color, color: "rgba(0,0,0,.4)" }}
              >
                <small>Slippage</small>
              </Badge>
            </Col>
            <Col xs={{ order: 1 }} sm={{ span: 8, order: 2 }}>
              <VictoryPie
                style={{ labels: { fontSize: 30, fill: "rgba(0,0,0,.5)" } }}
                labelComponent={
                  <VictoryPortal>
                    <VictoryLabel />
                  </VictoryPortal>
                }
                width={250}
                height={250}
                origin={{ x: 125, y: 125 }}
                labelRadius={({ radius }) => radius + 5}
                labels={({ datum }) => `$ ${numeral(datum.y).format("0.0a")}`}
                radius={(inputValueSwapped + outputValueSwapped + Math.abs(slippage.value)) / 300}
                theme={theme}
                style={{ labels: { fontSize: 15, fill: "rgba(0,0,0,.5)" } }}
                colorScale={[
                  outputPool.outputAsset.color,
                  inputPool.inputAsset.color,
                  slippage.color
                ]}
                data={[
                  { x: "Output Value", y: outputValueSwapped },
                  { x: "Input Value", y: inputValueSwapped },
                  { x: "Slippage", y: Math.abs(slippage.value) }
                ]}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={6}>
          <Row className="d-flex align-items-center pt-3">
            <Col xs={{ order: 2 }} sm={{ span: 7, order: 1 }} className="mb-4 mb-sm-0">
              <VictoryPie
                style={{ labels: { fontSize: 30, fill: "rgba(0,0,0,.5)" } }}
                labelComponent={
                  <VictoryPortal>
                    <VictoryLabel />
                  </VictoryPortal>
                }
                width={200}
                height={200}
                padding={0}
                labelRadius={({ radius }) => radius + 5}
                origin={{ x: 100, y: 100 }}
                labels={({ datum }) => `$ ${numeral(datum.y).format("0.0a")}`}
                radius={(totalPoolsValue.value + Math.abs(slippage.value)) / 200000}
                theme={theme}
                colorScale={[inputPool.inputAsset.color, totalPoolsValue.color]}
                style={{ labels: { fontSize: 15, fill: "rgba(0,0,0,.5)" } }}
                data={[
                  { x: "Input Value", y: inputValueSwapped },
                  { x: "Total Pool Value", y: totalPoolsValue.value }
                ]}
              />
            </Col>
            <Col xs={{ order: 1 }} sm={{ span: 5, order: 2 }} className="d-flex flex-column">
              <Badge
                className="overline text-truncate mb-1"
                style={{ backgroundColor: inputPool.inputAsset.color, color: "rgba(0,0,0,.4)" }}
              >
                <small>Swap BNB Value</small>
              </Badge>
              <Badge
                className="overline text-truncate"
                style={{ backgroundColor: theme.colors[0], color: "rgba(0,0,0,.4)" }}
              >
                <small>Total Pool Value</small>
              </Badge>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default ExternalToExternal;
