import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { VictoryLabel, VictoryPie, VictoryPortal } from "victory";

function formatNumber(num) {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

const startingTotalRuneForEmission = 220447472;
const yearlyEmissionFactor = 6;

const getYearlyEmissions = () => {
  let remainingTotalRune = startingTotalRuneForEmission;
  let emitted = [];
  let yearlyEmissions = [];
  const year = 10;

  for (let i = 1; i <= year; i++) {
    emitted = emitted.concat(remainingTotalRune / yearlyEmissionFactor);
    remainingTotalRune = remainingTotalRune - emitted[emitted.length - 1];

    const emittedThisYear = emitted[emitted.length - 1];
    const totalRuneEmitted = startingTotalRuneForEmission - remainingTotalRune;

    yearlyEmissions = yearlyEmissions.concat({
      year: i,
      perYear: {
        total: emittedThisYear,
        toNodeOperators: emittedThisYear * 0.666666667,
        toLiquidityProviders: emittedThisYear * 0.333333334,
        inflationRate: (emittedThisYear / remainingTotalRune) * 100
      },
      totalRuneEmitted,
      remainingRune: remainingTotalRune
    });
  }

  return yearlyEmissions;
};

const RuneEmissionCalculator = ({ theme }) => {
  const [year, setYear] = useState(1);
  const [showCalculation, setShowCalculation] = useState(false);

  const yearlyEmissions = getYearlyEmissions();

  return (
    <>
      <Row>
        <Col xs={12} lg={6}>
          <Form.Check
            label="Show calculation"
            type="switch"
            id="showCalculation"
            onChange={() => setShowCalculation(!showCalculation)}
            value={showCalculation}
            className="mb-2 text-muted"
          />
          <div className="mb-3">
            <Form.Group>
              <Form.Label className="overline mb-1">Years after network launch</Form.Label>
              <Form.Control
                as="select"
                value={year}
                onChange={e => setYear(e.target.value)}
                style={{ maxWidth: 100 }}
              >
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
                <option>6</option>
                <option>7</option>
                <option>8</option>
                <option>9</option>
                <option>10</option>
              </Form.Control>
            </Form.Group>
          </div>
          <div className="mb-3">
            <div className="overline mb-1">Emitted in year {year}</div>
            {showCalculation && (
              <>
                <div className="text-muted">
                  1/{yearlyEmissionFactor} of Remaining Rune after year {year - 1}
                  <br />
                  {yearlyEmissions[year - 2]
                    ? `ᚱ ${formatNumber(
                        Math.round(yearlyEmissions[year - 2].remainingRune)
                      )} / ${yearlyEmissionFactor}`
                    : `ᚱ ${formatNumber(
                        Math.round(startingTotalRuneForEmission)
                      )} / ${yearlyEmissionFactor}`}{" "}
                  =
                </div>
              </>
            )}
            <div className="h4 mb-0">
              ᚱ {formatNumber(Math.round(yearlyEmissions[year - 1].perYear.total))}
            </div>
          </div>
          <div className="mb-3">
            <div className="overline mb-1">Total Rune emitted after year {year}</div>
            {showCalculation &&
              yearlyEmissions
                .filter(yearlyEmission => yearlyEmission.year <= year)
                .map((yearlyEmission, i) => (
                  <div className="text-muted" key={yearlyEmission.totalRuneEmitted}>
                    Year {i + 1}. ᚱ {formatNumber(Math.round(yearlyEmission.perYear.total))}
                    {yearlyEmission.year < year ? " +" : " ="}
                  </div>
                ))}
            <div className="h4 mb-0">
              ᚱ {formatNumber(Math.round(yearlyEmissions[year - 1].totalRuneEmitted))}
            </div>
          </div>
          <div className="mb-3">
            <div className="overline mb-1">Remaining Rune after year {year}</div>
            {showCalculation && (
              <>
                <div className="text-muted">
                  Total to be emitted - Total Rune emitted after year {year}
                </div>
                <div className="text-muted">
                  ᚱ {formatNumber(Math.round(startingTotalRuneForEmission))} - ᚱ{" "}
                  {formatNumber(Math.round(yearlyEmissions[year - 1].totalRuneEmitted))}
                </div>
              </>
            )}
            <div className="h4 mb-0">
              ᚱ {formatNumber(Math.round(yearlyEmissions[year - 1].remainingRune))}
            </div>
          </div>
        </Col>

        <Col xs={{ span: 10, offset: 1 }} lg={{ span: 6, offset: 0 }}>
          <VictoryPie
            theme={theme}
            data={[
              {
                x: "Node Operators",
                y: yearlyEmissions[year - 1].perYear.toNodeOperators
              },
              {
                x: "Liquidity Providers",
                y: yearlyEmissions[year - 1].perYear.toLiquidityProviders
              }
            ]}
            width={500}
            labels={({ datum }) => `${datum.x} ᚱ${formatNumber(Math.round(datum.y))}`}
            labelComponent={
              <VictoryPortal>
                <VictoryLabel />
              </VictoryPortal>
            }
          />
        </Col>
      </Row>
    </>
  );
};

export default RuneEmissionCalculator;
