import numeral from "numeral";
import React from "react";
import { Badge, Collapse } from "react-bootstrap";
import Form from "react-bootstrap/Form";

const Pool = ({ pool, showDetail = false, theme }) => {
  return (
    <>
      <div className="mb-1">
        <h6 className="text-center mb-1">{pool.title}</h6>
        <div className="d-flex align-items-top">
          <div className="text-right w-50 pr-1">
            <Badge style={{ backgroundColor: pool.inputAsset.color }}>
              <h6 style={{ color: "rgba(0,0,0,.4)" }} className="mb-0">
                {pool.inputAsset.ticker}
              </h6>
            </Badge>
          </div>
          <div className="w-50 pl-1">
            <Badge style={{ backgroundColor: pool.outputAsset.color }}>
              <h6 className="mb-0" style={{ color: "rgba(0,0,0,.4)" }}>
                {pool.outputAsset.ticker}
              </h6>
            </Badge>
          </div>
        </div>
      </div>
      <Collapse in={showDetail}>
        <div>
          <div className="overline text-center">Price</div>
          <div className="mb-1 d-flex align-items-top">
            <div className="text-right w-50 pr-1" xs={6}>
              <h6 className="mb-0">
                <small>$</small>
                {numeral(pool.inputAsset.priceUSD).format("0.00")}
              </h6>
            </div>
            <div className="w-50 pl-1">
              <h6 className="mb-0">
                <small>$</small>
                {numeral(pool.outputAsset.priceUSD).format("0.00")}
              </h6>
            </div>
          </div>
          <div className="overline text-center">Balance</div>
          <div className="mb-1 d-flex align-items-top">
            <div className="text-right w-50 pr-1" xs={6}>
              <h6 className="mb-0">{numeral(pool.inputAsset.balance).format("0.0a")}</h6>
            </div>
            <div className="w-50 pl-1">
              <h6 className="mb-0">{numeral(pool.outputAsset.balance).format("0.0a")}</h6>
            </div>
          </div>
        </div>
      </Collapse>
      <div className="overline text-center">Value</div>
      <div className="mb-1 d-flex align-items-top">
        <div className="text-right w-50 pr-1" xs={6}>
          <h6 className="mb-0">
            <small>$</small>
            {numeral(pool.inputAsset.value).format("0.0a")}
          </h6>
          {/* {showCalculation && (
            <small className="text-muted">
              <div>
                {pool.inputAsset.ticker} Balance * {pool.inputAsset.ticker} Price
              </div>
              <div>
                {formatNumber(inputPoolInputAssetBalance.toFixed(2))} * $
                {pool.inputAsset.priceUSD.toFixed(2)}
              </div>
            </small>
          )} */}
        </div>
        <div className="w-50 pl-1">
          <h6 className="mb-0">
            <small>$</small>
            {numeral(pool.outputAsset.value).format("0.0a")}
          </h6>
          {/* {showCalculation && (
            <small className="text-muted">
              <div>
                {pool.outputAsset.ticker} Balance * {pool.outputAsset.ticker} Price
              </div>
              <div>
                {formatNumber(inputPoolOutputAssetBalance.toFixed(2))} * $
                {pool.outputAsset.priceUSD.toFixed(2)}
              </div>
            </small>
          )} */}
        </div>
      </div>
      <div className="text-center mb-1">
        <Badge
          style={{ color: "rgba(0,0,0,.4)", backgroundColor: theme.colors[0] }}
          className="overline mb-0"
        >
          Total Value
        </Badge>
        <h6 className="mb-0">
          <small>$</small>
          {numeral(pool.totalPoolValueUSD).format("0.0a")}
        </h6>
        <Form.Control
          type="range"
          max={10000000}
          onChange={e => pool.setTotalPoolValueUSD(Number(e.target.value))}
          value={Number(pool.totalPoolValueUSD)}
          className="w-25 mx-auto"
        />
      </div>
    </>
  );
};

export default Pool;
