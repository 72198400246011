import numeral from "numeral";
import React from "react";
import Badge from "react-bootstrap/Badge";
import Collapse from "react-bootstrap/Collapse";
import Form from "react-bootstrap/Form";

export function Swap({
  setInput,
  input,
  inputAsset,
  outputAsset,
  output,
  inputValueSwapped,
  outputValueSwapped,
  slippage,
  showCalculation
}) {
  return (
    <>
      <h6 className="text-center mb-1">Swap</h6>
      <div className="mb-1 d-flex align-items-top">
        <div className="text-right w-50 pr-1" xs={6}>
          <Badge
            className="overline mb-1"
            style={{ backgroundColor: inputAsset.color, color: "rgba(0,0,0,.4)" }}
          >
            Swap {inputAsset.ticker}
          </Badge>
          <div className="d-flex align-items-center">
            <div className="w-50 mr-1">
              <Form.Control
                type="range"
                max={1000}
                onChange={e => setInput(Number(e.target.value))}
                value={Number(input)}
              />
            </div>
            <div className="w-50">
              <Form.Control
                type="number"
                size="sm"
                className="text-right pr-1"
                onChange={e => setInput(Number(e.target.value))}
                value={Number(input)}
              />
            </div>
          </div>
        </div>

        <div className="w-50 pl-1">
          <Badge
            className="overline"
            style={{ backgroundColor: outputAsset.color, color: "RGBA(0, 0, 0, 0.4)" }}
          >
            For {outputAsset.ticker}
          </Badge>
          <h6 className="mb-0 mt-1">{numeral(output).format("0.0a")}</h6>
        </div>
      </div>
      <Collapse in={showCalculation}>
        <div className="text-center text-muted mb-1" style={{ fontSize: "smaller" }}>
          <div>Output calculation</div>
          <div>
            {outputAsset.ticker} Output = ({inputAsset.ticker} Amt * {outputAsset.ticker} Balance *{" "}
            {inputAsset.ticker} Balance) /<br />({inputAsset.ticker} Amt + {inputAsset.ticker}{" "}
            Balance)
            <sup>2</sup>
          </div>
          <div>
            {numeral(output).format("0.0a")} = ({numeral(input).format("0.0a")} *{" "}
            {numeral(outputAsset.balance).format("0.0a")} *{" "}
            {numeral(inputAsset.balance).format("0.0a")}) / ({numeral(input).format("0.0a")} +{" "}
            {numeral(inputAsset.balance).format("0.0a")})<sup>2</sup>
          </div>
        </div>
      </Collapse>
      <div className="overline text-center">Value</div>
      <div className="mb-1 d-flex align-items-top">
        <div className="text-right w-50 pr-1" xs={6}>
          <h6 className="mb-0">
            <small>$</small>
            {numeral(inputValueSwapped).format("0.00a")}
          </h6>
        </div>
        <div className="w-50 pl-1">
          <h6 className="mb-0">
            <small>$</small>
            {numeral(outputValueSwapped).format("0.00a")}
          </h6>
        </div>
      </div>
      <div className="mb-1 text-center">
        <Badge
          className="overline"
          style={{ backgroundColor: slippage.color, color: "rgba(0,0,0,.4)" }}
        >
          Slippage
        </Badge>
        <h6 className="mb-0">
          <small>$</small>
          {numeral(slippage.value).format("0.0a")} <small>or</small>{" "}
          {Math.abs(slippage.percentage.toFixed(1))}
          <small>%</small>
        </h6>
        <Collapse in={showCalculation}>
          <div>
            <small className="text-muted">
              <div className="text-muted">
                {outputAsset.ticker} Value Swapped - {inputAsset.ticker} Value Swapped
              </div>
              <div className="text-muted">
                {numeral(outputValueSwapped).format("$0.0")} -{" "}
                {numeral(inputValueSwapped).format("$0.0")}
              </div>
            </small>
          </div>
        </Collapse>
      </div>
    </>
  );
}
