import BigNumber from "bignumber.js";
import numeral from "numeral";
import React, { useState } from "react";
import { Badge, Button, Card, Col, Collapse, Form, InputGroup, Row } from "react-bootstrap";
import { MdAdd, MdRefresh, MdRemove } from "react-icons/md";
import { useQuery } from "react-query";
import { VictoryLabel, VictoryPie, VictoryPortal } from "victory";
import { fetchPrices } from "../../lib/helpers";

const getSS = (stakedRune, stakedExternalAsset, runeBalance, externalAssetBalance) => {
  // formula: ((stakedRune / (stakedRune + runeBalance) + stakedExternalAsset / (stakedExternalAsset + externalAssetBalance)) / 2) * 100;

  const stakedRuneBN = new BigNumber(stakedRune);
  const stakedExternalAssetBN = new BigNumber(stakedExternalAsset);
  const runeBalanceBN = new BigNumber(runeBalance);
  const externalAssetBalanceBN = new BigNumber(externalAssetBalance);

  const rRValue = stakedRuneBN.plus(runeBalanceBN);
  const rTValue = stakedExternalAssetBN.plus(externalAssetBalanceBN);

  const rResult = stakedRuneBN.div(rRValue);
  const tResult = stakedExternalAssetBN.div(rTValue);

  const value = rResult
    .plus(tResult)
    .div(2)
    .multipliedBy(100);
  return value;
};

const initialState = {
  totalPoolValueUSD: 5000000,
  stakedExternalAsset: 4500,
  stakedRune: 500000,
  dailyVolume: 750000,
  daysProvided: 365,
  impermanentLoss: 0.00027
};

const ProvidingLiquidity = ({ theme }) => {
  const { status, data, error } = useQuery(["thorchain,binancecoin"], fetchPrices);
  const BNBPriceUSD = status === "success" ? data.binancecoin.usd : "Loading...";
  const RUNEPriceUSD = status === "success" ? data.thorchain.usd : "Loading...";

  const [totalPoolValueUSD, setTotalPoolValueUSD] = useState(initialState.totalPoolValueUSD);
  const [stakedExternalAsset, setStakedExternalAsset] = useState(initialState.stakedExternalAsset);
  const [stakedRune, setStakedRune] = useState(initialState.stakedRune);
  const [dailyVolume, setDailyVolume] = useState(initialState.dailyVolume);
  const [daysProvided, setDaysProvided] = useState(initialState.daysProvided);
  const [impermanentLoss, setImpermanentLoss] = useState(initialState.impermanentLoss);

  const externalAssetBalance = totalPoolValueUSD / 2 / BNBPriceUSD;
  const runeBalance = totalPoolValueUSD / 2 / RUNEPriceUSD;
  const [fee, setFee] = useState(0.004);
  const [showReturnsDetails, setShowReturnsDetails] = useState(true);
  const [showPoolDetails, setShowPoolDetails] = useState(true);

  const handleReset = () => {
    setTotalPoolValueUSD(initialState.totalPoolValueUSD);
    setStakedExternalAsset(initialState.stakedExternalAsset);
    setStakedRune(initialState.stakedRune);
    setDailyVolume(initialState.dailyVolume);
    setDaysProvided(initialState.daysProvided);
    setImpermanentLoss(initialState.impermanentLoss);
  };

  const pool = {
    inputAsset: {
      ticker: "BNB",
      price: BNBPriceUSD,
      notStaked: {
        value: (totalPoolValueUSD / 2 / BNBPriceUSD) * BNBPriceUSD,
        balance: totalPoolValueUSD / 2 / BNBPriceUSD,
        color: "#FFDC00"
      },
      staked: {
        value: stakedExternalAsset * BNBPriceUSD,
        balance: stakedExternalAsset,
        color: "#998400"
      },
      total: {
        value: (externalAssetBalance + stakedExternalAsset) * BNBPriceUSD,
        balance: externalAssetBalance + stakedExternalAsset
      },
      color: "#FFDC00"
    },
    outputAsset: {
      ticker: "RUNE",
      price: RUNEPriceUSD,
      notStaked: {
        value: (totalPoolValueUSD / 2 / RUNEPriceUSD) * RUNEPriceUSD,
        balance: totalPoolValueUSD / 2 / RUNEPriceUSD,
        color: theme.colors[2]
      },
      staked: {
        value: stakedRune * RUNEPriceUSD,
        balance: stakedRune,
        color: "#007B8F"
      },
      total: {
        value: (runeBalance + stakedRune) * RUNEPriceUSD,
        balance: runeBalance + stakedRune
      },
      color: theme.colors[2]
    }
  };

  const poolOwnership = getSS(
    pool.outputAsset.staked.balance,
    pool.inputAsset.staked.balance,
    pool.outputAsset.notStaked.balance,
    pool.inputAsset.notStaked.balance
  );
  const totalPoolValue = pool.inputAsset.total.value + pool.outputAsset.total.value;

  const providedValue = pool.inputAsset.staked.value + pool.outputAsset.staked.value;

  const dailyFee = dailyVolume * fee;
  const feesAfterDaysProvided = dailyFee * daysProvided;
  const yourShareAfterDaysProvided = feesAfterDaysProvided * (poolOwnership / 100);
  const stakedExternalAssetValue = stakedExternalAsset * BNBPriceUSD;
  const stakedRuneValue = stakedRune * RUNEPriceUSD;
  const valueOfStakedAssets = stakedExternalAssetValue + stakedRuneValue;
  const yourImpermanentLoss = yourShareAfterDaysProvided * (impermanentLoss * daysProvided);
  const yourTotalReturn = yourShareAfterDaysProvided - yourImpermanentLoss;
  const principalPercentageChange =
    (valueOfStakedAssets + yourTotalReturn - valueOfStakedAssets) / valueOfStakedAssets;
  const principlePercentageChangeAnnualized = (principalPercentageChange / daysProvided) * 365;
  const impermanentLossPerYear = impermanentLoss * 365;
  return (
    <>
      <Row noGutters className="mb-2">
        <Col xl={9} className="pr-sm-2">
          <Card>
            <Card.Body>
              <h5 className="mb-0">Pool</h5>

              <VictoryPie
                style={{ labels: { fontSize: 50, fill: "rgba(0,0,0,.5)" } }}
                labelComponent={
                  <VictoryPortal>
                    <VictoryLabel />
                  </VictoryPortal>
                }
                width={500}
                height={250}
                origin={{ x: 250, y: 125 }}
                labelRadius={({ radius }) => radius + 5}
                labels={({ datum }) => datum.y && `${numeral(datum.y).format("$0.00a")}`}
                radius={totalPoolValue / 89999}
                theme={theme}
                style={{ labels: { fontSize: 10, fill: "rgba(0,0,0,.5)" } }}
                colorScale={[
                  pool.outputAsset.notStaked.color,
                  pool.outputAsset.staked.color,
                  pool.inputAsset.notStaked.color,
                  pool.inputAsset.staked.color
                ]}
                data={[
                  { x: "Pooled RUNE", y: pool.outputAsset.notStaked.value, z: pool.outputAsset },
                  { x: "Staked RUNE", y: pool.outputAsset.staked.value, z: pool.outputAsset },
                  { x: "Pooled BNB", y: pool.inputAsset.notStaked.value, z: pool.inputAsset },
                  { x: "Staked BNB", y: pool.inputAsset.staked.value, z: pool.inputAsset }
                ]}
              />
              <h6 className="mb-0">Provide</h6>
              <Row className=" mb-2" noGutters>
                <Col sm={2} xs={3} className="mb-1 mb-sm-0 pr-1">
                  <Badge
                    className="overline h4 mb-0"
                    style={{
                      backgroundColor: pool.inputAsset.staked.color,
                      color: "rgba(255,255,255,.8)"
                    }}
                  >
                    {pool.inputAsset.ticker}
                  </Badge>
                  <div>
                    {numeral(stakedExternalAsset).format("0,0.00a")}
                    <Form.Control
                      type="range"
                      max={10000}
                      onChange={e => setStakedExternalAsset(Number(e.target.value))}
                      value={Number(stakedExternalAsset)}
                    />
                  </div>
                </Col>
                <Col sm={2} xs={3} className="mb-1 mb-sm-0 pr-1">
                  <Badge
                    className="overline h4 mb-0"
                    style={{
                      backgroundColor: pool.outputAsset.staked.color,
                      color: "rgba(255,255,255,.8)"
                    }}
                  >
                    {pool.outputAsset.ticker}
                  </Badge>
                  <div>
                    {numeral(stakedRune).format("0,0.00a")}
                    <Form.Control
                      type="range"
                      max={1000000}
                      onChange={e => setStakedRune(Number(e.target.value))}
                      value={Number(stakedRune)}
                    />
                  </div>
                </Col>
                <Col sm={2} xs={3} className="mb-1 mb-sm-0 pr-1">
                  <Badge variant="light" className="overline">
                    Value
                  </Badge>
                  <div>{numeral(providedValue).format("$0,0.00a")}</div>
                </Col>
                <Col sm={2} xs={3} className="mb-1 mb-sm-0 pr-1">
                  <Badge variant="light" className="overline">
                    Share
                  </Badge>
                  <div>{numeral(poolOwnership / 100).format("0.00%")}</div>
                </Col>
                <Col sm={2} xs={3} className="mb-1 mb-sm-0 pr-1">
                  <Badge variant="light" className="overline">
                    For
                  </Badge>
                  <div>
                    {daysProvided} <small>days</small>
                  </div>
                  <Form.Control
                    type="range"
                    max={365 * 3}
                    onChange={e => setDaysProvided(Number(e.target.value))}
                    value={Number(daysProvided)}
                  />
                </Col>
              </Row>
              <h6 className="mb-0">Pooled</h6>
              <Row noGutters>
                <Col sm={2} xs={3} className="mb-1 mb-sm-0 pr-1">
                  <Badge
                    className="overline h4 mb-0"
                    style={{
                      backgroundColor: pool.inputAsset.notStaked.color,
                      color: "rgba(0,0,0,.4)"
                    }}
                  >
                    {pool.inputAsset.ticker}
                  </Badge>
                  <div>{numeral(pool.inputAsset.notStaked.balance).format("0,0.00a")}</div>
                </Col>
                <Col sm={2} xs={3} className="mb-1 mb-sm-0 pr-1">
                  <Badge
                    className="overline h4 mb-0"
                    style={{
                      backgroundColor: pool.outputAsset.notStaked.color,
                      color: "rgba(0,0,0,.4)"
                    }}
                  >
                    {pool.outputAsset.ticker}
                  </Badge>
                  <div>{numeral(pool.outputAsset.notStaked.balance).format("0,0.00a")}</div>
                </Col>
                <Col sm={2} xs={3} className="mb-1 mb-sm-0 pr-1">
                  <Badge variant="light" className="overline">
                    Value
                  </Badge>
                  <div>{numeral(totalPoolValueUSD).format("$0,0.00a")}</div>
                  <Form.Control
                    type="range"
                    max={10000000}
                    onChange={e => setTotalPoolValueUSD(Number(e.target.value))}
                    value={Number(totalPoolValueUSD)}
                  />
                </Col>
              </Row>
              <div
                className="mb-1"
                style={{ color: theme.colors[5], cursor: "pointer", fontSize: "smaller" }}
                onClick={() => setShowPoolDetails(!showPoolDetails)}
              >
                {showPoolDetails ? (
                  <span>
                    <MdRemove /> Show less
                  </span>
                ) : (
                  <span>
                    <MdAdd /> Show more
                  </span>
                )}
              </div>
              <Collapse in={showPoolDetails}>
                <div>
                  <h6 className="mb-0">Factors</h6>
                  <Row>
                    <Col xs={4} sm={3} className="mb-1 mb-sm-0">
                      <Badge
                        className="overline h4 mb-0"
                        style={{
                          backgroundColor: pool.inputAsset.notStaked.color,
                          color: "rgba(0,0,0,.4)"
                        }}
                      >
                        {pool.inputAsset.ticker} price
                      </Badge>
                      <div>{numeral(pool.inputAsset.price).format("$0,0.00a")}</div>
                    </Col>
                    <Col xs={4} sm={3} className="mb-1 mb-sm-0">
                      <Badge
                        className="overline h4 mb-0"
                        style={{
                          backgroundColor: pool.outputAsset.notStaked.color,
                          color: "rgba(0,0,0,.4)"
                        }}
                      >
                        {pool.outputAsset.ticker} price
                      </Badge>
                      <div>{numeral(pool.outputAsset.price).format("$0,0.00a")}</div>
                    </Col>
                    <Col xs={4} sm={3} className="mb-1 mb-sm-0">
                      <div className="overline" title="Average Volume/Day">
                        Avg Vol/Day
                      </div>
                      <div>{numeral(dailyVolume).format("$0,0.00a")}</div>
                      <Form.Control
                        type="range"
                        max={5000000}
                        onChange={e => setDailyVolume(Number(e.target.value))}
                        value={Number(dailyVolume)}
                      />
                    </Col>
                    <Col xs={4} sm={3} className="mb-1 mb-sm-0">
                      <div className="overline">Avg Fee</div>
                      <div>{numeral(fee).format("0.0%")}</div>
                    </Col>
                    <Col xs={4} sm={3} className="mb-1 mb-sm-0">
                      <div className="overline text-truncate" title="Impermanent Loss/Year">
                        Imp Loss/Day
                      </div>
                      <div>
                        <InputGroup size="sm">
                          <Form.Control
                            type="number"
                            value={impermanentLoss}
                            onChange={e => setImpermanentLoss(e.target.value)}
                            step={0.0001}
                            min={0}
                          />
                          <InputGroup.Append>
                            <InputGroup.Text>%</InputGroup.Text>
                          </InputGroup.Append>
                        </InputGroup>
                      </div>
                    </Col>
                    <Col xs={4} sm={3} className="mb-1 mb-sm-0">
                      <div className="overline text-truncate" title="Impermanent Loss/Day">
                        Imp Loss/Year
                      </div>
                      <div>{numeral(impermanentLossPerYear).format("0.00%")}</div>
                    </Col>
                  </Row>
                </div>
              </Collapse>
            </Card.Body>
          </Card>
        </Col>
        <Col xl={3}>
          <div className="position-sticky" style={{ top: "7.5rem" }}>
            <Button className="mb-2" variant="outline-primary" onClick={handleReset}>
              <MdRefresh /> Reset
            </Button>
            <Card>
              <Card.Body>
                <h5 className="mb-1">Returns</h5>
                <div>
                  <div>
                    <div className="mb-1">
                      <div className="overline">Profit</div>
                      <div>{numeral(yourTotalReturn).format("$0,0.00")}</div>
                    </div>
                    <div className="mb-1">
                      <div className="overline">
                        <small>%</small> Change
                      </div>
                      <div>{numeral(principalPercentageChange).format("0.0%")}</div>
                    </div>
                    <div
                      className={showReturnsDetails && "mb-1"}
                      style={{ color: theme.colors[5], cursor: "pointer", fontSize: "smaller" }}
                      onClick={() => setShowReturnsDetails(!showReturnsDetails)}
                    >
                      {showReturnsDetails ? (
                        <span>
                          <MdRemove /> Show less
                        </span>
                      ) : (
                        <span>
                          <MdAdd /> Show more
                        </span>
                      )}
                    </div>
                    <Collapse in={showReturnsDetails} className="mb-0">
                      <div>
                        <div className="mb-1">
                          <div className="overline">+ Provided</div>
                          <div>{numeral(providedValue + yourTotalReturn).format("$0,0.00")}</div>
                        </div>
                        <div className="mb-1">
                          <div className="overline">Annualized</div>
                          <div>{numeral(principlePercentageChangeAnnualized).format("0.0%")}</div>
                        </div>
                        <div>
                          <div className="overline">Impermanent Loss</div>
                          <div>{numeral(yourImpermanentLoss).format("$0,0.0")}</div>
                        </div>
                      </div>
                    </Collapse>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>
        </Col>
      </Row>
      <div>
        <div className="w-text">
          Notes
          <ul>
            <li>
              Prices update in real-time here. In real-life they'll be updated by arbitrage activity
              and so update speed won't always be instant.
            </li>
            <li>Normal rewards going to Liquidity Providers</li>
            <li>
              Impermanent Loss is sourced from estimate in Economics Paper – 0.0003%/day or 10%/year
            </li>
            <li>
              For now, this tool assumes that the price in and price out is the same, which is very
              unlikely. To simulate price changes, tweak the Impermanent Loss/Day number.
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default ProvidingLiquidity;
