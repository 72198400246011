import React from "react";
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryLegend,
  VictoryPortal,
  VictoryStack,
  VictoryTooltip
} from "victory";
import { formatNumber } from "../lib/helpers";

const startingTotalRuneForEmission = 220447472;
const yearlyEmissionFactor = 6;

export const getYearlyEmissions = () => {
  let remainingTotalRune = startingTotalRuneForEmission;
  let emitted = [];
  let yearlyEmissions = [];
  const year = 10;

  for (let i = 1; i <= year; i++) {
    emitted = emitted.concat(remainingTotalRune / yearlyEmissionFactor);
    remainingTotalRune = remainingTotalRune - emitted[emitted.length - 1];

    const emittedThisYear = emitted[emitted.length - 1];
    const totalRuneEmitted = startingTotalRuneForEmission - remainingTotalRune;

    yearlyEmissions = yearlyEmissions.concat({
      perYear: {
        total: emittedThisYear,
        toNodeOperators: emittedThisYear * 0.666666667,
        toLiquidityProviders: emittedThisYear * 0.333333334,
        inflationRate: (emittedThisYear / remainingTotalRune) * 100
      },
      totalRuneEmitted,
      remainingRune: remainingTotalRune
    });
  }

  return yearlyEmissions;
};

const RuneEmissionChart = ({ theme }) => {
  const yearlyEmissions = getYearlyEmissions();

  const nodeOperatorData = yearlyEmissions.map((yearNumber, i) => {
    return { x: i + 1, y: yearNumber.perYear.toNodeOperators };
  });
  const liquidityProviderData = yearlyEmissions.map((yearNumber, i) => {
    return { x: i + 1, y: yearNumber.perYear.toLiquidityProviders };
  });

  const data = [nodeOperatorData, liquidityProviderData];

  return (
    <VictoryChart
      domainPadding={{ x: 20, y: 20 }}
      padding={{ top: 0, bottom: 50, left: 50 }}
      style={{ fontSize: "10px" }}
      theme={theme}
    >
      <VictoryLegend
        x={175}
        y={50}
        gutter={20}
        style={{ border: { stroke: "none" }, labels: { fontSize: 10 } }}
        data={[{ name: "To Node Operators" }, { name: "To Liquidity Providers" }]}
      />
      <VictoryStack>
        {data.map((data, i) => {
          return (
            <VictoryBar
              data={data}
              key={i}
              labelComponent={<VictoryTooltip />}
              labels={({ datum }) => `ᚱ ${formatNumber(Math.round(datum.y))}`}
              barWidth={30}
              renderInPortal={true}
              animate={{
                duration: 200,
                onLoad: { duration: 1000 }
              }}
            />
          );
        })}
      </VictoryStack>
      <VictoryPortal>
        <VictoryAxis
          dependentAxis
          tickFormat={tick => `ᚱ ${formatNumber(tick)}`}
          label="ᚱ Emitted"
          style={{ axisLabel: { padding: 70, fontSize: 10 }, tickLabels: { fontSize: 10 } }}
          fixLabelOverlap={true}
        />
      </VictoryPortal>
      <VictoryAxis
        tickFormat={data[0].map(datum => datum.x)}
        label="Year after launch"
        style={{ axisLabel: { padding: 30, fontSize: 10 }, tickLabels: { fontSize: 10 } }}
      />
    </VictoryChart>
  );
};

export default RuneEmissionChart;
