import { useState } from "react";

const useRangeSlider = initialValue => {
  const [value, setValue] = useState(initialValue);

  const bind = {
    value,
    onChange: e => setValue(e)
  };

  return [value, bind];
};

export default useRangeSlider;
