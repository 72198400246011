import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { Circle, Layer, Stage, Text } from "react-konva";
import useRangeSlider from "../hooks/useRangeSlider";

const getSystemStatus = percentRuneBonded => {
  if (percentRuneBonded > 75) {
    return {
      title: "🐢 Inefficient",
      nodeRewards: "⏬",
      liquidityRewards: "⏫",
      description:
        "Too much capital is being used to secure the system. The protocol changes incentives to encourage more Liquidity Providers to take part and fewer Node Operators."
    };
  } else if (percentRuneBonded > 67) {
    return {
      title: "Over-Bonded",
      nodeRewards: "⬇️",
      liquidityRewards: "⬆️",
      description: "More capital is being used to protect the system than is required."
    };
  } else if (percentRuneBonded === 67) {
    return {
      title: "👌 Optimal",
      nodeRewards: "None",
      liquidityRewards: "None",
      description: "The system's running safely and efficiently."
    };
  } else if (percentRuneBonded < 67 && percentRuneBonded >= 60) {
    return {
      title: "Under-Bonded",
      nodeRewards: "⬆️",
      liquidityRewards: "⬇️",
      description: "The system is close to being unsafe."
    };
  } else if (percentRuneBonded < 60) {
    return {
      title: "🚨 Unsafe",
      nodeRewards: "⏫",
      liquidityRewards: "⏬",
      description:
        "Node Operators working together could steal assets. They have less at stake than they could earn by stealing assets. Rewards change to raise Node participation and lower Liquidity participation."
    };
  }
};

const Balancer = ({ network = {} }) => {
  const [percentRuneBonded, bindPercentRuneBonded] = useRangeSlider(67);
  const percentRunePooled = 100 - percentRuneBonded;

  return (
    <>
      <div className="text-center text-muted">Slide to explore</div>
      <div className="mt-2 pb-4">
        <Slider
          value={bindPercentRuneBonded.value}
          onChange={bindPercentRuneBonded.onChange}
          min={20}
          max={100}
          marks={{
            50: {
              style: { fontSize: "1rem" },
              label: "Unsafe"
            },
            60: {
              style: { fontSize: "1rem" },
              label: "Under-bonded"
            },
            67: {
              style: { fontSize: "1rem" },
              label: "Optimal"
            },
            75: {
              style: { fontSize: "1rem" },
              label: "Over-Bonded"
            },
            100: {
              style: { fontSize: "1rem" },
              label: "Inefficient"
            }
          }}
          trackStyle={{ backgroundColor: network.brandColors[0] }}
          railStyle={{ backgroundColor: network.brandColors[1] }}
        />
      </div>
      <Row className="mb-2 d-flex align-items-center">
        <Col xl={3}>
          <div className="overline">System status</div>
          <h4 className="mb-0">{getSystemStatus(percentRuneBonded).title}</h4>
        </Col>
        <Col xl={9}>{getSystemStatus(percentRuneBonded).description}</Col>
      </Row>
      <div className="d-flex justify-content-between mb-2">
        <div style={{}} className="w-25 pr-2">
          <div className="overline mb-0 text-truncate">Change to node rewards</div>
          <div>
            <span className="h4 mt-0">{getSystemStatus(percentRuneBonded).nodeRewards}</span>
          </div>
        </div>
        <div style={{ color: network.brandColors[0] }} className="w-25 pr-2">
          <div className="overline mb-0 text-truncate">RUNE Bonded</div>
          <div>
            <span className="h4 mt-0" style={{ color: network.brandColors[0] }}>
              {percentRuneBonded}
            </span>
            %
          </div>
        </div>
        <div className="text-left w-25 pl-2" style={{ color: network.brandColors[1] }}>
          <div className="overline mb-0 text-truncate">RUNE Pooled</div>
          <div>
            <span className="h4 mt-0" style={{ color: network.brandColors[1] }}>
              {percentRunePooled}
            </span>
            %
          </div>
        </div>
        <div className="text-left w-25 pl-2" style={{}}>
          <div className="overline mb-0 text-truncate">Change to liquidity rewards</div>
          <div>
            <span className="h4 mt-0">{getSystemStatus(percentRuneBonded).liquidityRewards}</span>
          </div>
        </div>
      </div>
      <Stage
        width={2000}
        height={300}
        style={{ width: "100%", height: "275px", overflow: "hidden" }}
        className="mb-3"
      >
        <Layer style={{ width: "100%", height: "100%" }}>
          <Text
            x={200 - 200 / 2}
            y={10}
            align="center"
            text="Bonded Capital"
            fontSize={18}
            fontFamily="Normal, sans-serif"
            fontColor={network.brandColors[0]}
            width={200}
          />
          <Circle x={200} y={150} radius={percentRuneBonded} fill={network.brandColors[0]} />
          <Text
            x={700 - 200 / 2}
            y={10}
            text="Pooled Capital"
            fontSize={18}
            fontFamily="Normal, sans-serif"
            width={200}
            align="center"
          />
          <Circle x={600} y={150} radius={percentRunePooled} fill={network.brandColors[1]} />
          <Text
            x={600 - 200 / 2}
            y={250}
            text="Rune"
            align="center"
            fontSize={18}
            fontFamily="Normal, sans-serif"
            width={200}
          />
          <Circle x={800} y={150} radius={percentRunePooled} fill={network.brandColors[1]} />
          <Text
            x={800 - 200 / 2}
            y={250}
            text="External Assets"
            fontSize={18}
            fontFamily="Normal, sans-serif"
            width={200}
            align="center"
          />
        </Layer>
      </Stage>
    </>
  );
};

export default Balancer;
