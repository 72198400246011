import React, { useState } from "react";
import { Badge, Col, Form, Row } from "react-bootstrap";
import { VictoryLabel, VictoryPie, VictoryPortal } from "victory";

const Income = ({ theme }) => {
  const [swapFees, setSwapFees] = useState(500);
  const [blockRewards, setBlockRewards] = useState(1000);
  const [desiredSwapFees, setDesiredSwapFees] = useState(33.33333);
  const desiredBlockRewards = 100 - desiredSwapFees;
  const systemIncome = swapFees + blockRewards;

  const percentRunePooled = Math.round((swapFees / systemIncome) * 100);
  const percentRuneBonded = Math.round((blockRewards / systemIncome) * 100);

  const getPoolShareFactor = () => {
    const poolShareFactor = (
      (desiredBlockRewards + desiredSwapFees) /
      (desiredBlockRewards - desiredSwapFees)
    ).toFixed(1);

    if (poolShareFactor > 0) {
      return poolShareFactor;
    } else {
      return 0;
    }
  };

  const poolShareFactor = getPoolShareFactor();
  const percentRewardsToLiquidityProviders =
    100 / poolShareFactor === Infinity ? 0 : 100 / poolShareFactor;
  const percentRewardsToNodeOperators = 100 - percentRewardsToLiquidityProviders;

  const feeDifference = Math.round(percentRunePooled - percentRewardsToLiquidityProviders);
  const feeDeficit = Math.abs(feeDifference < 0 ? feeDifference : 0);
  const feeExcess = feeDifference > 0 ? feeDifference : 0;

  const totalNodeRewards = Math.round((systemIncome * percentRewardsToNodeOperators) / 100);
  const nodeRewardsFromBlockRewards = Math.round(
    ((percentRuneBonded - feeDeficit) / 100) * systemIncome
  );
  const nodeRewardsFromFeeExcess = Math.round((feeExcess / 100) * systemIncome);

  const totalLiquidityRewards = Math.round(
    (systemIncome * percentRewardsToLiquidityProviders) / 100
  );
  const liquidityRewardsFromSwapFees = Math.round(
    ((percentRunePooled - feeExcess) / 100) * systemIncome
  );
  const liquidityRewardsFromFeeDeficit = Math.round((feeDeficit / 100) * systemIncome);

  return (
    <div className="mb-5">
      <Row className="d-flex align-items-center mb-3">
        <Col xs={12} sm={6}>
          <div className="d-flex align-items-center justify-content-between mb-2">
            <Badge
              className="overline mr-1"
              style={{ width: 300, backgroundColor: theme.colors[0], color: "white" }}
            >
              Swap Fees
            </Badge>
            <Form.Control
              type="range"
              max={1000}
              onChange={e => setSwapFees(Number(e.target.value))}
              value={swapFees}
            />
          </div>
          <div className="d-flex align-items-center justify-content-between mb-2">
            <Badge
              className="overline mr-1"
              style={{ width: 300, backgroundColor: theme.colors[1], color: "white" }}
            >
              Block Rewards
            </Badge>
            <Form.Control
              type="range"
              max={1000}
              onChange={e => setBlockRewards(Number(e.target.value))}
              value={blockRewards}
            />
          </div>
          <div>
            <div className="overline">Total</div>
            <div>
              <div className="h4 mb-0">ᚱ {systemIncome}</div>
              <div className="text-muted">Not representative numbers</div>
            </div>
          </div>
        </Col>
        <Col xs={12} sm={6}>
          <VictoryPie
            style={{ labels: { fontSize: 30, fill: "rgba(0,0,0,.5)" } }}
            labelRadius={systemIncome / 10 + 30}
            labelComponent={
              <VictoryPortal>
                <VictoryLabel />
              </VictoryPortal>
            }
            width={750}
            height={400}
            origin={{ x: 375, y: 200 }}
            labels={({ datum }) => `ᚱ ${datum.y} | ${datum.z}%`}
            radius={systemIncome / 10}
            theme={theme}
            style={{ labels: { fontSize: 30, fill: "rgba(0,0,0,.5)" } }}
            data={[
              { x: "Swap Fees", y: swapFees, z: percentRunePooled },
              { x: "Block Rewards", y: blockRewards, z: percentRuneBonded }
            ]}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm={4} xs={6}>
          <div className="overline mb-1 text-truncate">Bonded:Pooled Rune</div>
          <div style={{ height: 25 }} className="d-flex align-items-center">
            <Form.Control
              type="range"
              max={51}
              onChange={e => setDesiredSwapFees(Number(e.target.value))}
              value={desiredSwapFees}
            />
          </div>
          <VictoryPie
            style={{ labels: { fill: "rgba(0,0,0,.5)" } }}
            labelRadius={75}
            width={200}
            height={150}
            origin={{ x: 100, y: 75 }}
            labels={({ datum }) => `${datum.x} ${Math.round(datum.y)}%`}
            style={{ labels: { fontSize: 10 } }}
            radius={50}
            theme={theme}
            colorScale={[theme.grey, theme.charcoal]}
            labelComponent={
              <VictoryPortal>
                <VictoryLabel />
              </VictoryPortal>
            }
            data={[
              { x: "Pooled", y: desiredSwapFees },
              { x: "Bonded", y: desiredBlockRewards }
            ]}
          />
        </Col>
        <Col sm={4} xs={6}>
          <div className="overline mb-1 text-truncate">Income Split</div>
          <div style={{ height: 25 }}></div>
          {/* poolShareFactor = (totalBonded + totalPooled)/(totalBonded - totalPooled)
          <br />
          {poolShareFactor}= ({desiredBlockRewards} + {desiredSwapFees})/({desiredBlockRewards} -{" "}
          {desiredSwapFees})
          <br />
          {percentRewardsToLiquidityProviders} = 100 / {poolShareFactor}
          <br />
          {percentRewardsToNodeOperators} = 100 - {percentRewardsToLiquidityProviders}
          <br />
          percentRewardsToLiquidityProviders = 100 / poolShareFactor
          <br />
          percentRewardsToNodeOperators = 100 - percentRewardsToLiquidityProviders
          */}
          <VictoryPie
            style={{ labels: { fill: "rgba(0,0,0,.5)" } }}
            labelRadius={55}
            width={200}
            height={150}
            origin={{ x: 100, y: 75 }}
            labels={({ datum }) => `${Math.round(datum.y)}%`}
            radius={50}
            theme={theme}
            colorScale={[theme.colors[3], theme.colors[2]]}
            data={[
              { x: "To Liquidity Providers", y: percentRewardsToLiquidityProviders },
              { x: "To Node Operators", y: percentRewardsToNodeOperators }
            ]}
          />
        </Col>
        <Col sm={4} xs={6}>
          <div className="overline mb-1 text-truncate">Fee Difference</div>
          <div style={{ height: 25, lineHeight: 1 }}>
            {feeDifference > 0 && (
              <small style={{ color: theme.colors[4] }}>
                Node Rewards supplemented by Swap Fees
              </small>
            )}
            {feeDifference < 0 && (
              <small style={{ color: theme.colors[5] }}>
                Liquidity Rewards supplemented by Block Rewards
              </small>
            )}
          </div>
          <VictoryPie
            style={{ labels: { fill: "rgba(0,0,0,.5)" } }}
            labelRadius={55}
            width={200}
            height={150}
            origin={{ x: 100, y: 75 }}
            labels={({ datum }) => datum.y && `ᚱ ${Math.round(datum.z)} | ${Math.round(datum.y)}%`}
            radius={50}
            theme={theme}
            colorScale={[theme.colors[0], theme.colors[1], theme.colors[4], theme.colors[5]]}
            labelComponent={
              <VictoryPortal>
                <VictoryLabel />
              </VictoryPortal>
            }
            data={[
              {
                x: "Swap Fees to LPs",
                y: percentRunePooled - feeExcess,
                z: ((percentRunePooled - feeExcess) / 100) * systemIncome
              },
              {
                x: "Block Rewards to NOs",
                y: percentRuneBonded - feeDeficit,
                z: ((percentRuneBonded - feeDeficit) / 100) * systemIncome
              },
              feeDifference > 0 && {
                x: "Swap Fees to NOs",
                y: feeExcess,
                z: (feeExcess / 100) * systemIncome
              },
              feeDifference < 0 && {
                x: "Block Rewards to LPs",
                y: feeDeficit,
                z: (feeDeficit / 100) * systemIncome
              }
            ]}
          />
        </Col>
      </Row>
      <section className="mb-5">
        <Row>
          <Col sm={3} xs={6} className="mb-3">
            <h6 style={{ color: theme.colors[2] }}>Node Rewards</h6>
            <div className="d-flex align-items-baseline">
              <div className="overline mr-1">Total</div>
              <h6>
                <small>ᚱ </small>
                {totalNodeRewards}
              </h6>
            </div>
            <div className="d-flex align-items-baseline" style={{ color: theme.colors[1] }}>
              <small className="overline mr-1 text-truncate" title="From Block Rewards">
                From Block Rewards
              </small>
              <h6 style={{ color: theme.colors[1] }}>
                <small>ᚱ</small>
                {nodeRewardsFromBlockRewards}
              </h6>
            </div>
            <div className="d-flex align-items-baseline" style={{ color: theme.colors[4] }}>
              <small className="overline mr-1 text-truncate" title="Swap Fee Supplement">
                Swap Fee Supplement
              </small>
              <h6 style={{ color: theme.colors[4] }}>
                <small>ᚱ</small>
                {nodeRewardsFromFeeExcess}
              </h6>
            </div>
          </Col>
          <Col sm={3} xs={6} className="mb-3">
            <VictoryPie
              style={{ labels: { fill: "rgba(0,0,0,.5)" } }}
              labelRadius={totalNodeRewards / 20 + 10}
              width={200}
              height={200}
              origin={{ x: 100, y: 100 }}
              labels={({ datum }) => datum.y && `ᚱ ${Math.round(datum.y)}`}
              radius={totalNodeRewards / 20}
              colorScale={[theme.colors[1], theme.colors[4]]}
              theme={theme}
              labelComponent={
                <VictoryPortal>
                  <VictoryLabel />
                </VictoryPortal>
              }
              data={[
                {
                  x: "From Block Rewards",
                  y: nodeRewardsFromBlockRewards
                },
                nodeRewardsFromFeeExcess > 0 && {
                  x: "Fee Excess",
                  y: nodeRewardsFromFeeExcess
                }
              ]}
            />
          </Col>
          <Col sm={3} xs={6}>
            <h6 style={{ color: theme.colors[3] }}>Liquidity Rewards</h6>
            <div className="d-flex align-items-baseline">
              <small className="overline mr-1 text-truncate" title="Total">
                Total
              </small>
              <h6>
                <small>ᚱ</small>
                {totalLiquidityRewards}
              </h6>
            </div>
            <div className="d-flex align-items-baseline" style={{ color: theme.colors[0] }}>
              <small className="overline mr-1 text-truncate" title="From Swap Fees">
                From Swap Fees
              </small>
              <h6 style={{ color: theme.colors[0] }}>
                <small>ᚱ</small>
                {liquidityRewardsFromSwapFees}
              </h6>
            </div>
            <div className="d-flex align-items-baseline" style={{ color: theme.colors[5] }}>
              <small className="overline mr-1 text-truncate" title="Block Reward Supplement">
                Block Reward Supplement
              </small>
              <h6 style={{ color: theme.colors[5] }}>
                <small>ᚱ</small>
                {liquidityRewardsFromFeeDeficit}
              </h6>
            </div>
          </Col>
          <Col sm={3} xs={6}>
            <VictoryPie
              style={{ labels: { fill: "rgba(0,0,0,.5)" } }}
              labelRadius={totalLiquidityRewards / 20 + 10}
              width={200}
              height={200}
              origin={{ x: 100, y: 100 }}
              labels={({ datum }) => datum.y && `ᚱ ${Math.round(datum.y)}`}
              radius={totalLiquidityRewards / 20}
              colorScale={[theme.colors[0], theme.colors[5]]}
              theme={theme}
              labelComponent={
                <VictoryPortal>
                  <VictoryLabel />
                </VictoryPortal>
              }
              data={[
                {
                  x: "From Block Rewards",
                  y: liquidityRewardsFromSwapFees
                },
                liquidityRewardsFromFeeDeficit > 0 && {
                  x: "Fee Excess",
                  y: liquidityRewardsFromFeeDeficit
                }
              ]}
            />
          </Col>
        </Row>
      </section>
    </div>
  );
};

export default Income;
