import React, { useState } from "react";
import Balancer from "../components/Balancer";
import Layout from "../components/layout";
import PortableText from "../components/portableText";
import RuneEmissionCalculator from "../components/RuneEmissionCalculator";
import RuneEmissionChart from "../components/RuneEmissionChart";
import SEO from "../components/seo";
import SpotlightLayout2 from "../components/SpotlightLayout2";
import DebtRiskForMinters from "../components/thorchain/DebtRiskForMinters";
import Income from "../components/thorchain/Income";
import ProvidingLiquidity from "../components/thorchain/ProvidingLiquidity";
import ExternalToExternal from "../components/thorchain/swaps/ExternalToExternal";
import ExternalToRune from "../components/thorchain/swaps/ExternalToRune";
import { buildImageObj, slugify } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import { createVictoryTheme } from "../styles/victoryTheme";

export const query = graphql`
  query SystemComponentTemplateQuery($id: String!) {
    systemComponent: allSanitySystemComponent(filter: { id: { eq: $id } }) {
      nodes {
        title
        videoId
        _rawDescription2(resolveReferences: { maxDepth: 10 })
        _rawText(resolveReferences: { maxDepth: 10 })
        _rawImage(resolveReferences: { maxDepth: 10 })
        network {
          _rawLogo
          _rawLogoIcon
          brandColors
          title
          slug {
            current
          }
        }
      }
    }
  }
`;

const pageComponents = {
  thorchain: {
    "emission-schedule": [
      { title: "Rune emitted per year", Component: RuneEmissionCalculator },
      { title: "Rune emitted over 10 years", Component: RuneEmissionChart }
    ],
    balancer: [{ title: "", Component: Balancer }],
    "system-income": [{ title: "", Component: Income }],
    swaps: [
      { title: "BNB to RUNE", Component: ExternalToRune },
      { title: "BNB to BTC", Component: ExternalToExternal }
    ],
    "providing-liquidity": [{ title: "", Component: ProvidingLiquidity }],
    "debt-risk-for-minters": [{ title: "", Component: DebtRiskForMinters }]
  }
};

const SystemComponent = props => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const { spotlightLayoutResource } = props.pageContext;
  let { systemComponent } = props.data;
  systemComponent = systemComponent.nodes[0];
  let { network, videoId, title, _rawDescription2, _rawText, _rawImage } = systemComponent;

  const victoryTheme = createVictoryTheme(network.brandColors);

  console.dir(_rawImage);

  return (
    <Layout>
      <SEO title={title} />
      <SpotlightLayout2 resource={spotlightLayoutResource}>
        <div className="mb-5">
          <div
            className="position-sticky py-2 border-bottom"
            style={{ top: 0, backgroundColor: "white", zIndex: 100 }}
          >
            <h1 className="h3 mb-0">{title}</h1>
          </div>
          {_rawImage && (
            <img
              src={
                imageLoaded
                  ? imageUrlFor(buildImageObj(_rawImage))
                      .maxWidth(1000)
                      .quality(100)
                      .auto("format")
                      .url()
                  : _rawImage.asset.metadata.lqip
              }
              className="mb-3 img-fluid"
              onLoad={() => setImageLoaded(true)}
              style={{ height: "50vh", width: "100%", objectFit: "cover", transition: "all .2s" }}
            />
          )}
          {_rawDescription2 && (
            <p className="lead w-text mb-3">
              <PortableText blocks={_rawDescription2} />
            </p>
          )}
          {videoId && (
            <iframe
              width="100%"
              height="600px"
              frameborder="0"
              id="video"
              className="mb-3"
              style={{ border: "1px solid #f7f7f7" }}
              src={`https://www.youtube.com/embed/${videoId}`}
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          )}
          {pageComponents[network.slug.current][slugify(systemComponent.title)].length > 0 &&
            pageComponents[network.slug.current][slugify(systemComponent.title)].map(
              pageComponent => {
                const { title, Component } = pageComponent;

                return (
                  <div>
                    <hr />
                    {title && <h4>{title}</h4>}
                    <Component network={network && network} theme={victoryTheme} />
                  </div>
                );
              }
            )}
          {_rawText && (
            <>
              <hr />
              <div className="w-text">
                <h4>Explainer</h4>
                <PortableText blocks={_rawText} />
              </div>
            </>
          )}
        </div>
      </SpotlightLayout2>
    </Layout>
  );
};

export default SystemComponent;
